import { render, staticRenderFns } from "./HomeOrganizing.vue?vue&type=template&id=6790a952&scoped=true"
import script from "./HomeOrganizing.vue?vue&type=script&lang=ts"
export * from "./HomeOrganizing.vue?vue&type=script&lang=ts"
import style0 from "./HomeOrganizing.vue?vue&type=style&index=0&id=6790a952&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6790a952",
  null
  
)

export default component.exports