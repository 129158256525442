


































/* eslint-disable max-len */
import { gsap } from 'gsap'

import { HomeOrganizingText } from '@/inc/types'
import { defineComponent, PropType, ref, onMounted } from '@vue/composition-api'

import GWysiwyg from '@/components/g/Wysiwyg.vue'

export default defineComponent({
  name: 'HomeOrganizingText',
  components: {},
  props: {
    content: {
      type: Object as PropType<HomeOrganizingText>,
      required: true,
    },
  },

  setup() {
    const text = ref<InstanceType<typeof GWysiwyg> | null>(null)
    const picture = ref<HTMLElement | null>(null)
    let tl: ReturnType<typeof gsap.timeline> | null = null

    const onAppear = () => {
      tl!.play()
    }

    onMounted(() => {
      tl = gsap.timeline({ paused: true })

      if (window.innerWidth < 960) {
        tl.from(picture.value, {
          y: 50,
          opacity: 0,
        }).from(text.value!.$el, { y: 50, opacity: 0 }, 0)
      } else {
        tl.from(picture.value, {
          x: 50,
          opacity: 0,
          duration: 1.5,
        }).from(text.value!.$el, { y: 100 }, 0)
      }
    })

    return {
      text,
      picture,
      onAppear,
    }
  },
})
