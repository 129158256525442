


































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import HomeOrganizingHero from '@/components/home-organizing/Hero.vue'
import HomeOrganizingText from '@/components/home-organizing/Text.vue'
import HomeOrganizingOrganizer from '@/components/home-organizing/Organizer.vue'
import CardSlider from '@/components/flexible/CardSlider.vue'

export default defineComponent({
  name: 'HomeOrganizing',
  components: {
    HomeOrganizingHero,
    HomeOrganizingText,
    HomeOrganizingOrganizer,
    CardSlider,
  },
  setup() {
    return {
      ...useGetters(['content']),
    }
  },
})
